<template>
  <dx-util-popup
    ref="paymentWidgetRef"
    height="auto"
    width="900px"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="paymentWidgetShown"
    @hidden="paymentWidgetHidden"
  >
    <div>
      <dx-data-grid
        id="creditCardGrid"
        ref="creditCardGridRef"
        class="mb-2"
        :data-source="creditCards"
        height="auto"
        :selection="{ mode: 'single' }"
        no-data-text="Please add credit card to pay membership charges by using Account > My Company menu."
        @selection-changed="onSelectionChanged"
      >
        <dx-column data-field="nickname" caption="Card Details" :allow-sorting="false" />
        <dx-column data-field="cardExp" caption="Card Expiry" :width="100" :allow-sorting="false" />
        <dx-column data-field="isDefault" caption="Is Default" cell-template="isDefaultTemplate" :width="100" :allow-sorting="false" />
        <dx-column data-field="itemAction" caption="Use This" cell-template="useThisTemplate" alignment="center" :width="100" :allow-sorting="false" />
        <template #isDefaultTemplate="{data}">
          <p-icon :name="data.value ? 'feather-check' :'none'" :class="data.value ? 'text-success' :''" />
        </template>
        <template #useThisTemplate="{data}">
          <div>
            <p-icon :name="data.data.id === selectedCreditCardId ? 'feather-disc' : ''" :color="data.data.id === selectedCreditCardId ? 'success' : ''" />
          </div>
        </template>
      </dx-data-grid>
    </div>
    <div class="d-flex justify-content-end">
      <div class="px-1 col-12 col-sm-6 mb-1" style="max-width: 300px;">
        <table class="my-half text-warning">
          <tr>
            <td>Payment Method</td>
            <td class="text-uppercase">
              {{ selectedMethod }}
            </td>
          </tr>
          <tr>
            <td>Payment Amount</td>
            <td class="text-right">
              {{ getCurrencyFormat(paymentRequest.chargeAmount) }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Pay Now" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import membershipChargeService from '@/http/requests/membership/charge'
import { Notify } from '@/@robustshell/utils'
import useCurrentUser from '@/libs/app/current-user'
import staxService from '@/http/requests/external/stax'
import { currencyFormatter } from '@core/utils/filter'
import userManagementService from '@/http/requests/system/userManagementService'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    paymentRequest: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { userCompanyId } = useCurrentUser()
    return {
      userCompanyId,
    }
  },
  data() {
    return {
      popupTitle: '',
      creditCards: [],
      selectedCreditCardId: '',
      selectedMethod: '',
    }
  },
  computed: {
    paymentWidget() {
      return this.$refs.paymentWidgetRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.paymentWidget.show()
      },
    },
  },
  mounted() {
    this.getCreditCards()
  },
  methods: {
    paymentWidgetShown() {
      this.popupTitle = 'Payment Methods'
    },
    paymentWidgetHidden() {
    // Clear form when popup is closed
    },
    closePopup() {
      this.paymentWidget.hide()
    },
    onSelectionChanged(e) {
      this.selectedCreditCardId = e.selectedRowsData[0].id
    },
    async getCreditCards() {
      const response = await staxService.getAllCreditCardsByCompanyId(this.userCompanyId)
      if (response && response.length > 0) {
        const defaultCard = response.find(c => c.isDefault)
        this.selectedCreditCardId = defaultCard.id
        this.selectedMethod = defaultCard.method
        this.creditCards.length = 0
        this.creditCards = [...response]
      }
    },
    handleSubmit(e) {
      this.payCharge()
      this.$emit('emit-form-saved')
      this.closePopup()
    },
    async payCharge() {
      try {
        await membershipChargeService.payCharges(this.selectedCreditCardId, this.paymentRequest.chargeIds)
        this.$swal({
          icon: 'success',
          title: 'Payment Completed!',
          text: 'You have successfully paid your membership fee.',
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(async () => {
          // localStorage.clear()
          await userManagementService.refreshAuthData().then(() => {
            window.location.reload()
          })
          // this.$router.push({ name: 'auth-login' })
        })
      } catch (err) {
        const message = err.message || err
        Notify.error(message)
      }
    },
    getCurrencyFormat(data) {
      return currencyFormatter(data)
    },
  },
}
</script>

<style lang="scss" scoped>
td {
  padding: 1px 16px 1px 1px;
}
</style>
